.position(@type;
@top: auto;
@right: auto;
@bottom: auto;
@left: auto;
@width: auto;
@height: auto) {
  position: @type;
  top: @top;
  right: @right;
  bottom: @bottom;
  left: @left;
  width: @width;
  height: @height;
}

.partial-circle(@circle-size: 110px;
@circle-background: #ccc;
@circle-color: #0286ee;
@inset-size: 104px;
@inset-color: #fbfbfb;
@transition-length: 1s;
) {
  width: @circle-size;
  height: @circle-size;
  background-color: @circle-background;
  border-radius: 50%;
  * {
    .backface-visibility(hidden);
  }
  .circle {
    .mask, .fill, .shadow {
      width: @circle-size;
      height: @circle-size;
      position: absolute;
      border-radius: 50%;
    }
    .mask,
    .fill {
      .backface-visibility(hidden);
      .transition(@transition-length);
      border-radius: 50%;
    }
    .mask {
      clip: rect(0px, @circle-size, @circle-size, @circle-size/2);
      .fill {
        clip: rect(0px, @circle-size/2, @circle-size, 0px);
        background-color: @circle-color;
      }
    }
  }
  .inset {
    width: @inset-size;
    height: @inset-size;
    position: absolute;
    margin-left: (@circle-size - @inset-size)/2;
    margin-top: (@circle-size - @inset-size)/2;
    border-radius: 50%;
  }
  @i: 0;
  @increment: 180deg / 100;
  .loop (@i) when (@i <=100) {
    &[data-progress="@{i}"] {
      .circle {
        .mask.full, .fill {
          .rotate(@increment * @i);
        }
        .fill.fix {
          .rotate(@increment * @i * 2);
        }
      }
    }
    .loop(@i + 1);
  }
  .loop(@i);
}

.partial-circle-splash-page(@circle-size: 60px;
@circle-background: #fff;
@circle-color: #aaa;
@inset-size: 56px;
@inset-color: #fbfbfb;
@transition-length: 1s;
) {
  width: @circle-size;
  height: @circle-size;
  background-color: @circle-background;
  border-radius: 50%;
  * {
    .backface-visibility(hidden);
  }
  .circle {
    .mask, .fill, .shadow {
      width: @circle-size;
      height: @circle-size;
      position: absolute;
      border-radius: 50%;
    }
    .mask,
    .fill {
      .backface-visibility(hidden);
      .transition(@transition-length);
      border-radius: 50%;
    }
    .mask {
      clip: rect(0px, @circle-size, @circle-size, @circle-size/2);
      .fill {
        clip: rect(0px, @circle-size/2, @circle-size, 0px);
        background-color: @circle-color;
      }
    }
  }
  .inset {
    width: @inset-size;
    height: @inset-size;
    position: absolute;
    margin-left: (@circle-size - @inset-size)/2;
    margin-top: (@circle-size - @inset-size)/2;
    border-radius: 50%;
  }
  @i: 0;
  @increment: 180deg / 100;
  .loop (@i) when (@i <=100) {
    &[data-progress="@{i}"] {
      .circle {
        .mask.full, .fill {
          .rotate(@increment * @i);
        }
        .fill.fix {
          .rotate(@increment * @i * 2);
        }
      }
    }
    .loop(@i + 1);
  }
  .loop(@i);
}



.generate-days(@n, @i: 1) when (@i =< @n) {
  .d-@{i} ul {
    width: (@i * 20%);
  }
  .generate-days(@n, (@i + 1));
}
.d-1:before,.d-2:before,.d-3:before,.d-4:before,.d-5:before  {
  display: none;
}

.clearfix() {
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

.datapicker-container {
  position: relative;
}
.datapicker-container .react-datepicker__tab-loop .react-datepicker-popper {
  inset: 0 auto auto -80px !important;
}
.datapicker-container .react-datepicker__input-container input {
  padding: 0.5rem 0.75rem !important;
}