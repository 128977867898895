* {
  .box-sizing(border-box);
  -webkit-tap-highlight-color:rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent; // For some Androids
}

html, button, input, textarea, select {
  outline: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: @base-font-size;
}

body {
  .type-regular();
  color: @color-full;
  font-size: @base-font-size; // This sets the baseline so we can use multiples of 4 & (r)ems
}

// Default type layout
h1, h2, h3, h4, h5, h6 {
  .type-regular();
  margin: 0;
  padding: 0;
}

h1 {
  .font-l1();
}

h2 {
  .font-m3();
}

h3 {
  .font-m2();
}

h4 {
  .font-m1();
}

h5 {
  .font-s2();
  .type-bold();
  color: @color-medium-rare;
  text-transform: uppercase;
}

h6 {
  color: @color-medium;
}

p {
  .font-s3();
}

sub,
sup {
  font-size: .8em;
}

sub {
  bottom: -.2em;
}

sup {
  top: -.2em;
}

b {
  .type-bold();
}

em {
  .type-italic();
}

a, a:active, a:focus {
   outline: none;
}

input::-moz-focus-inner {
  border: 0;
}

/** TOOLTIP PERSONALIZED */
[data-title]:hover:after {
  opacity: 0.9;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
  font-size: x-small;
}
[data-title]:after {
  content: attr(data-title);
  background-color: #4a4a4a;
  border-radius: 4px;
  color: #fafafa;
  font-size: x-small;
  position: absolute;
  padding: 7px 7px 7px 7px;
  bottom: -3.5em;
  left: -50%;
  white-space: pre-line;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
  overflow-wrap: break-word;
}
[data-title] {
  position: relative;
}

details > summary {
  list-style: none;
}
details > summary::marker {
  display: none;
}