.avatar {
  border-radius: 10em;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;

  .image-avatar {
    width: 110px;
    height: 110px;
  }
}

.login-status {
  a { 
    color: #867261;
    &:hover { color: #FF5A4B }
  }
}