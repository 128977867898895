#gnome-loading {
  bottom:50%;
  position:absolute;
  -webkit-animation:linear infinite;
  -webkit-animation-name:run;
  -webkit-animation-duration:8s;
}

@-webkit-keyframes run  {
  0% {
     left:0;
     -webkit-transform:rotateY(180deg);
  }
  
  50% {
     left:100%;
     -webkit-transform:rotateY(180deg);
  }
  
  51% {
     -webkit-transform:rotateY(0deg);
  }
  
  99% {
     left:0;
     -webkit-transform:rotateY(0deg);
  }
  
  100% {
     left:0;
     -webkit-transform:rotateY(180deg);
  }

}