.companies-list {
  .list-item {
    // cursor: pointer;
    margin: 10px 0 10px 0;
    .title {
      color: @color-full;
      &.not-verified {
        color: @color-negative;
      }
    }
    .favorite {
      color: @color-medium-rare;
      .rem-px(margin-right, 4);
      &.icon-star-full {
        color: #ffb700;
      }
    }
    .access-codes {
      color: @color-medium;
      font-size: 0.8em;
    }
  }
  .favorite-action-list-item {
    margin: 10px 0 10px 0;
  }
  
  .list-item .autohide {
    display: none;
  }
  
  .list-item:hover .autohide {
    display: inline;
  }
}