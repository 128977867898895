.engagement-view {
  
  .program-stats-table { 
    width: 100%;
    max-width: 1000px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
    thead tr th { 
      text-align: left;
      padding: 1em;
      background: #ddd;
      width: 25%;
      font-weight: bold;
    }
    tbody tr td {
      text-align: left;
      padding: 1em;
    }
    tbody tr td abbr {
      cursor: pointer;
    }
    tbody tr td strong {
      font-weight: 600;
    }
    tbody tr.even td {
      background: #f0f0f0;
    }
  }
  
  ul.course-engagements-list {
    position: relative;
    list-style-type: decimal-leading-zero;
    margin-left: 3em;
    
    .session-numbers {
      padding-left: 12rem; 
      padding-top: 1em; 
      padding-bottom: .3rem;
      span {
        display: inline-block;
        width: 1.5rem;
        text-align: center;
        margin-right: 0.4rem;
        font-size: .8em;
      }
    }
      
    li {
      position: relative;
      margin-bottom: 0.2rem;
      line-height: 1.4;
      div {
        display: inline-block;
        vertical-align: text-top;
        &.user-course-info {
          position: absolute;
          width: 12rem;
          height: 1.5rem;
          overflow: hidden;
          span {
            white-space: nowrap;
            vertical-align: text-bottom;
          }
        }
        &.course-status {
          position: absolute;
          margin-left: 13rem;
          width: 3rem;
          height: 1.5rem;
          overflow: hidden;
          span {
            white-space: nowrap;
            vertical-align: text-bottom;
          }
        }
        &.chapters-statuses {
          margin-left: 12rem;
          span {
            display: inline-block;
            width: 1.5rem;
            height: 1.5rem;
            line-height: 1.5rem;
            text-align: center;
            font-size: 0.8rem;
            margin-bottom: 0.2rem;
            margin-right: 0.4rem;
            background-color: #666;
            &:last-child {
              margin-right: 0;
            }
          }
        }
        &.course-minutes {
          position: relative;
          height: 1.5rem;
          margin-left: .5rem;
          vertical-align: bottom;
          font-size: .8em;
          span {
            display: inline-block;
            width: 2rem;
            white-space: nowrap;
            vertical-align: text-bottom;
          }
        }
        &.assessment-results {
          margin-left: 0.5rem;
          div {
            display: inline-block;
            span {
              display: inline-block;
              width: 6rem;
              height: 1.5rem;
              line-height: 1.5rem;
              text-align: center;
              font-size: 0.8rem;
              margin-bottom: 0.2rem;
              margin-right: 0.4rem;
              background-color: transparent;
              cursor: pointer;
            }
          }
        }
        &.certificate-results {
          margin-left: 0.5em;
          a, span {
            display: inline-block;
            height: 1.5rem;
            line-height: 1.5rem;
            font-size: 0.8rem;
            background-color: transparent;
          }
        }
      }
    }
    &.skip-user {
      list-style-type: none;
      margin-left: 0;
      .session-numbers {
        display: none;
      }
      li {
        div {
          &.user-course-info {
            display: none;
          }
          &.chapters-statuses {
            margin-left: 0;
            display: inline-flex !important;
            span {
              width: 1rem;
              height: 1rem;
              line-height: 1rem;
              font-size: 0.5rem;
              margin-right: 0.3rem;
            }
          }
          &.course-minutes {
            display: none;
          }
        }
      }
    }
  }
  
  ol.user-view-list {
    .user-row {
      display: flex;
      align-items: center;
      margin-bottom: .2em;
      &:hover {
        background: #FEF0FF;
      }
      .user-name { 
        width: 12rem;
      }
    }
    .user-row-stripecontainer {
      display: flex;
      align-items: center;
      .actions-completed { 
        padding-right: 5px;
        font-size: .8rem;
        text-align: right;
        width: 28px;
      }
      .stripes {
        flex: 1;
        height: 24px;
      }
      .active-days {
        padding-left: 5px; 
        font-size: .8rem;
      }
      .day-stripe {
        display: inline-block;
        width: 2px;
        height: 24px;
        margin-right: 1px;
        text-align: center;
        font-size: .8em;
        color: white;
        position: relative;
      }
      .month-flag {
        position: absolute;
        top: -19px;
        left: -.5px;
        padding: 4px 5px 4px 5px;
        width: 50px;
        background: linear-gradient(to right, rgba(221,221,221,1) 0%,rgba(0,0,0,0) 100%);
        border-left: solid 2px #bbb;
        font-weight: bold;
        font-size: .7rem;
        color: #333;
        display: inline-block;
        text-align: left;
      }
    }    
  }
  
  div.user-eng-legend {
    font-size: .8rem;
    .legend-div {
      overflow: auto;
      margin-bottom: .2rem
    }
    .legend-square {
      float: left;
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
    .legend-description {
      display: inline-block;
      margin-top: .2rem
    }
  }
}

#course-engagement-progress {
  overflow: hidden;
}

.scroll-button-left, .scroll-button-right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #7e7e7e;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 3px 8px;
  z-index: 1;
  border-radius: 999px;
}

.scroll-button-left {
  left: 0;
}

.scroll-button-right {
  right: 0;
}

.scroll-button-left:hover, .scroll-button-right:hover {
  background: #555;
}

.scroll-container {
  width: 175px;
  overflow: hidden;
}
