a {
  .transition( all 200ms ease-in );
  cursor: pointer;
  text-decoration: none;
}
a:hover{
  color: #b50700;
}

.link-target-blank {
  a {
    @apply underline text-red-700 after:content-['_↗'];
  }  
}
