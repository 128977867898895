.user-study-content {
  h3, h4 {
    .rem-px(margin-top, 30);
  }
  h5 {
    .rem-px(margin-top, 16);
  }
  .description {
    .rem-px(font-size, 14);
  }
  .content {
    background-color: #aaa;
    .rem-px(padding, 10);
    ul {
      list-style-type: square;
      margin-top: 0;
      .rem-px(padding-left, 20);
      li {
        .rem-px(padding-bottom, 5);
        img { max-height: 200px; }
        .user-info { color: @color-well; .rem-px(padding-right, 5); }
      }
    }
  }
}