.dashboard-tour {
  &:before {
    content: '';
    position: absolute;
    top: -10px;
    left: 30px;
    // margin-left: -5px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #F7DD79;
  }
  [data-tour-elem="controls"] { justify-content: right !important }
  [data-tour-elem="left-arrow"] { margin-right: 0 !important }
  [data-tour-elem="right-arrow"] { margin-left: 0 !important }
  [data-tour-elem="badge"] {
    top: auto !important;
    bottom: 1.9rem !important;
    left: 1.5rem !important;
    background: transparent !important;
    box-shadow: none !important;
    color: #4A4A4A !important;
    font-size: 14px !important;
    font-family: inherit !important;
    font-weight: 600 !important;
  }
}
