@font-face {
  font-family: 'icomoon';
  src:  url('../../fonts/icomoon.eot?scsaak');
  src:  url('../../fonts/icomoon.eot?scsaak#iefix') format('embedded-opentype'),
    url('../../fonts/icomoon.ttf?scsaak') format('truetype'),
    url('../../fonts/icomoon.woff?scsaak') format('woff'),
    url('../../fonts/icomoon.svg?scsaak#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bullhorn:before {
  content: "\e91a";
}
.icon-book:before {
  content: "\e91f";
}
.icon-qrcode:before {
  content: "\e938";
}
.icon-clock:before {
  content: "\e94e";
}
.icon-calendar:before {
  content: "\e953";
}
.icon-bubble:before {
  content: "\e96b";
}
.icon-bubbles:before {
  content: "\e96c";
}
.icon-bubbles2:before {
  content: "\e96d";
}
.icon-bubble2:before {
  content: "\e96e";
}
.icon-bubbles3:before {
  content: "\e96f";
}
.icon-bubbles4:before {
  content: "\e970";
}
.icon-users:before {
  content: "\e972";
}
.icon-spinner:before {
  content: "\e97a";
}
.icon-spinner2:before {
  content: "\e97b";
}
.icon-spinner9:before {
  content: "\e982";
}
.icon-spinner11:before {
  content: "\e984";
}
.icon-search:before {
  content: "\e986";
}
.icon-key:before {
  content: "\e98d";
}
.icon-lock:before {
  content: "\e98f";
}
.icon-unlocked:before {
  content: "\e990";
}
.icon-bin:before {
  content: "\e9ac";
}
.icon-menu:before {
  content: "\e9bd";
}
.icon-menu3:before {
  content: "\e9bf";
}
.icon-menu4:before {
  content: "\e9c0";
}
.icon-earth:before {
  content: "\e9ca";
}
.icon-eye:before {
  content: "\e9ce";
}
.icon-eye-blocked:before {
  content: "\e9d1";
}
.icon-bookmark:before {
  content: "\e9d2";
}
.icon-star-empty:before {
  content: "\e9d7";
}
.icon-star-full:before {
  content: "\e9d9";
}
.icon-heart:before {
  content: "\e9da";
}
.icon-warning:before {
  content: "\ea07";
}
.icon-notification:before {
  content: "\ea08";
}
.icon-question:before {
  content: "\ea09";
}
.icon-plus:before {
  content: "\ea0a";
}
.icon-minus:before {
  content: "\ea0b";
}
.icon-cross:before {
  content: "\ea0f";
}
.icon-checkmark:before {
  content: "\ea10";
}
.icon-spell-check:before {
  content: "\ea12";
}
.icon-play3:before {
  content: "\ea1c";
}
.icon-arrow-left2:before {
  content: "\ea40";
}
.icon-sort-alpha-asc:before {
  content: "\ea48";
}
.icon-sort-alpha-desc:before {
  content: "\ea49";
}
.icon-sort-amount-asc:before {
  content: "\ea4c";
}
.icon-sort-amount-desc:before {
  content: "\ea4d";
}
.icon-new-tab:before {
  content: "\ea7e";
}
.icon-share2:before {
  content: "\ea82";
}
.icon-flickr:before {
  content: "\eaa0";
}
.icon-flickr2:before {
  content: "\eaa1";
}
