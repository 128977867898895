.btn {
  @apply border border-solid whitespace-nowrap rounded-md font-bold text-sm text-deepgray;

  &:focus {
    &:not(.disabled):not(.text):not(.span) {
      @apply focus:outline-none focus:ring-2 focus:ring-offset-2;
    }
  }

  &:hover {
    &:not(.disabled):not(.text):not(.span) {
      @apply hover:shadow-md;
    }
    &.text:not([disabled]), &.span.underlined:not(.disabled) {
      @apply hover:underline;
    }
    &.span:not(.underlined) {
      @apply hover:shadow-md;
    }
  }

  &:disabled {
    @apply opacity-50 cursor-not-allowed;
  }

  &.btn-big {
    @apply px-6 py-3;
  }

  &.btn-medium {
    @apply px-6 py-2;
  }

  &.btn-small {
    @apply px-4 py-1;
  }

  &.primary {
    @apply border-listenblue bg-listenblue text-white hover:text-white hover:bg-listenblue-lightish hover:border-listenblue-lightish focus:ring-listenblue-darker;
  }

  &.secondary {
    @apply border-lake bg-lake hover:text-deepgray hover:bg-lake-lightish hover:border-lake-lightish focus:ring-lake-darker;
  }

  &.team {
    @apply border-team bg-team hover:bg-yellow-400 hover:border-yellow-400 focus:ring-yellow-500;
  }

  &.edit {
    @apply border-team focus:ring-yellow-500 bg-team hover:border-yellow-400;
  }

  &.quote {
    @apply border-actions-quote bg-actions-quote hover:bg-yellow-300 hover:border-yellow-300 focus:ring-yellow-400;
  }

  &.danger {
    @apply border-coral bg-coral hover:bg-red-600 hover:border-red-600 text-white focus:ring-red-700;
  }

  &.default {
    @apply bg-transparent border-solid border-deepgray focus:ring-gray-500 hover:bg-white/5;
  }

  &.chat {
    @apply border-teak text-teak focus:ring-teak;
  }

  &.destructive {
    @apply border-solid border-coral bg-transparent hover:bg-coral-light hover:bg-opacity-30 hover:border-coral text-coral focus:ring-red-700;
  }

  &.text {
    @apply hover:text-black;
  }
}
