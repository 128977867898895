// Notification message (e.g., when unable to connect)
.notifications {
  .notification {
    .font-s2();
    background: rgba(51,51,51, .95);
    color: @color-empty;
    position: relative;
    width: 100%;

    &:nth-child(odd) {
      background: rgba(45,45,45, .95);

      .btn-primary {
        background: rgba(216,62,20, .78); // This is color-primary in rgba form
      }
    }

    .icon-spinner {
      .position(absolute, 35%, auto, auto, 12px);
      .animation(spin 2s infinite linear);
      color: @color-empty;
      font-size: 1.5em;
    }

    .icon-spinner + .title-notification {
      padding-left: 40px;
    }

    .title-notification {
      .font-s3();
      .type-bold();
      display: block;
      line-height: 1rem;
      padding: 1rem .75rem;
      span {
        margin-left: 0.3rem;
        &.green { color: green; }
        &.red { color: red; }
      }
    }

    .btn-primary {
      float: right;
      background: rgba(216,62,20, .85); // This is color-primary in rgba form
      height: 48px;
    }
  }
}
.blink-me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}