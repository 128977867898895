.ql-toolbar.ql-snow {
  background: #f5f5f5;

  .error & {
    border-color: #FF5A4B;
    background: #FFEEEB;
  }
}

.ql-container.ql-snow {
  font-size: 15px;
  background: white;
  transition: background .2s;
  
  .error & {
    border-color: #FF5A4B;
    background: #fff8f7;
  }

  strong {
    font-weight: bold;
  }
}