html, body {
  height: 100%;
  background-color: #F9F7F2;
}

.h-half-wrapper {
  display: block;
  overflow: hidden;
  .rem-px(padding-left, 5);
  .rem-px(padding-right, 5);
}

.h-wrapper {
  display: block;
  overflow: hidden;
  .rem-px(padding-left, 10);
  .rem-px(padding-right, 10);
}

.v-wrapper {
  .rem-px(margin-top, 10);
  .rem-px(margin-bottom, 10);
}

.clearfix {
  .clearfix();
}

#notifications-container {
  .position(fixed, auto, 0, 0, auto, 100%);
  .rem-px(max-width, 500);
  z-index: 1000; /* this one should be over everything */
}

#react-root {
  height: 100%;

  #page-containter {
    height: 100%;
    .display(flex);
    flex-direction: column;

    .feedback {
      font-size: 1rem !important;
      color: #ffb900;
    }
  }

  #app-bar-container {
    flex: 0;
  }

  #app-container {
    margin: 0 !important;
    flex: 1;
    overflow-y: auto;
    .display(flex);
    flex-direction: column;
    // height: 100%;
    // height: ~"calc(100% - 80px)";
    // .rem-px(margin, 10);
    background-color: #F9F7F2;
    header {
      .rem-px(margin-bottom, 10);
    }

    .container {
      position: relative;
      .flex(1);
      overflow-y: scroll;
      height: 100%;
      overflow: auto;
      .rem-px(padding, 20);
    }

    .grid-container {
      height: 100%;
      width: 100%;
      .flex(1);
      .display(flex);
      flex-direction: row;
      overflow-x: auto;

      .column {
        position: relative;
        height: 100%;
        min-width: 49.5%;
        .flex(1);
        .rem-px(padding, 20);
        overflow-y: auto;
        .column-close {
          position: absolute;
          top: 16px;
          right: 16px;
          color: @color-medium-rare;
          cursor: pointer;
        }
        .column-scroll-to {
          position: absolute;
          top: 50%;
          left: 0;
          cursor: pointer;
          &:before {
            content: "...";
          }
        }
        &.third {
          min-width: 35%;
        }
        &.two-thirds {
          min-width: 64%;
        }
        &.action-edit, &.chapter-edit, &.reward-edit {
          min-width: ~"calc(100% - 430px)";
          width: ~"calc(100% - 430px)";
          float: left;
          padding: 0;
          .rem-px(padding-right, 20);

          &.i6p {
            min-width: ~"calc(100% - 484px)";
            width: ~"calc(100% - 484px)";
          }
        }
        &.action-preview, &.chapter-preview, &.reward-preview, .course-preview {
          // sizes from http://www.kylejlarson.com/blog/iphone-6-screen-size-web-design-tips/
          min-width: 400px;
          width: 400px;
          float: right;
          background: #eee8d9;
          padding: 15px 10px 15px 15px;
          .rem-px(padding-right, 20);

          &.i6p {
            min-width: 439px;
            width: 439px;
          }

          &.left {
            float: left;
          }
        }
        &:first-child .column-scroll-to {
          display: none;
        }
        &:nth-child(2) .column-scroll-to {
          display: none;
        }
      }
    }

    .vertical-grid-container {
      height: 100%;
      width: 100%;
      .flex(1);
      .display(flex);
      flex-direction: column;
      overflow-y: scroll;

      .row {
        .flex(1);
        &.fixed {
          .flex(0);
        }
        &.padding {
          .rem-px(padding, 20);
          .rem-px(padding-bottom, 0);
          &:last-child {
            .rem-px(padding-bottom, 20);
          }
        }
      }
    }

    .welcome-container {
      height: 100%;
      padding: 0 28px;
      padding-top: 42px;
      width: 100%;
      flex-direction: column;
      .flex(1);
      .display(flex);
      .align-items(center);
      .justify-content(center);

      .welcome-content {
        padding: 30px;
        border-radius: 0.5rem;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
        color: black;
        a {
          text-decoration: underline;
          color: #b50700;
        }
      }

      ul {
        margin-top: 1rem;
        margin-left: 1rem;
      }
      li {
        list-style-type: disc;
        line-height: 1.4rem;
      }
    }

  }

  #app-footer {
    flex: 0;
    width: 100%;
    .rem-px(margin-top, 20);
    .rem-px(margin-bottom, 10);
    text-align: center;
    color: #666;
    font-size: 0.9rem;
  }
}

.tabs-100-height-fix {
  > div:nth-child(3) > div {
    height: 100%;
  }
}

.display-hover-container {
  .display-if-hover-block, .display-if-hover-inline-block {
    display: none;
  }
  &:hover {
    .display-if-hover-block {
      display: block
    }
    .display-if-hover-inline-block {
      display: inline-block;
    }
  }
}

@media screen and (min-width: 1800px) {
  #react-root #app-container .grid-container .column {
    min-width: 900px;
  }
}

// Filestack dialog has to be above material-ui popovers
.fp__overlay {
  z-index: 5000 !important;
}

.firehelp {
  min-width: 100px;
  background-color: #fff9ed;
  margin: 5px 0;
  padding: 20px;
  border-radius: 0.5rem;
  border: 1px solid #efefef;

  ol, ul {
    margin-top: 20px;
    li {
      line-height: 20px;
    }
  }
}

.settings-page {
  section {
    @apply bg-white border border-gray-200 rounded-md p-6;

    header {
      @apply mb-6;

      h3 {
        @apply text-lg font-bold leading-6 text-medgray;
      }

      .subtitle {
        @apply mt-1 text-sm leading-5 text-deepgray;
      }
    }
  }

  .menu-item {
    @apply rounded-md px-4 py-3 flex items-center text-sm font-bold w-full;

    svg {
      @apply inline w-4 h-4 mr-2 -mt-1;
    }

    &.active {
      @apply bg-lightwarmgray;
    }

    &.inactive {
      @apply text-deepgray hover:text-deepgray hover:bg-white;
    }
  }
}
