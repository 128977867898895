// Caps styling used in headers
.title-caps() {
  letter-spacing: .3em;
  text-indent: .3em;
  text-transform: uppercase;
}

// Adds an ellipses at the end of overflowing strings
.ellipsized() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.force-wrap() {
  word-wrap: break-word;
  word-break: break-all;
 -ms-word-break: break-all;
  word-break: break-word; // Non-standard for webkit
  .hyphens(auto);
}

.ellipsized {
  .ellipsized();
}