.user-status-circle {
  display: inline-block;
  border-radius: 50%;
  width: 0.9em;
  height: 0.9em;
  &.online-app {
    background-color: green;
  }
  &.online-editorial {
    background-color: orange;
  }
  &.online-app-editorial {
    background-color: purple;
  }
  &.offline {
    background-color: red;
  }
}
