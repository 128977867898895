.icon-ellipsis:before {
  content: "\22ee";
}

[class^="icon-"], [class*=" icon-"] {
  &.rotate {
    .animation(spin 2s infinite linear);
  }
}

.icon-arrow-up:before {
  content: "\2191";
}

.icon-arrow-down:before {
  content: "\2193";
}