.copy-and-paste {
  .font-s3();
  cursor: pointer;
  color: #bbb;
}

.icon-button {
  margin-left: 5px !important;
  margin-top: 15px !important;
  padding: 8px !important;

  svg {
    font-size: 16px;
  }
}

.date-picker {
  border: none;
  border-bottom: solid 1px #e0e0e0;
  padding-bottom: 3px;
  margin-top: 10px;
  width: 250px;
  &.graph {
    width: 120px;
  }
}

div.no-padding {
  padding: 0;
}
div.no-margin {
  margin: 0;
}