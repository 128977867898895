.table {
  display: inline-flex;
  .tr {
    width: 100% !important;
    height: 48px !important;
  }

  .th,
    .td {
      padding: 5px;
      position: relative;
      .resizer {
        display: inline-block;
        position: absolute;
        background: lightgray;
        margin-left: 2px;
        height: 100%;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;
        width: 1px;
        touch-action:none;
        &.isResizing {
          background: gray;
        }
      }
    }
 
  &.sticky {
    overflow-x: scroll;
    .header {
      position: sticky;
      z-index: 1;
      width: fit-content;
    }
    [data-sticky-td] {
      position: sticky;
    }
    .body {
      position: relative;
      z-index: 0 !important;
    }

    [data-sticky-last-left-td] {
      z-index: 2 !important;
      background-color: inherit;
      // -webkit-box-shadow: 8px 0px 30px 0px rgba(75,75,75,0.05); 
      // box-shadow: 8px 0px 30px 0px rgba(75, 75, 75, 0.05);
      // background-color: rgba(255, 255, 255, 0.9);
      // filter: drop-shadow(0 0 0.55rem lightgray)
    }

    [data-sticky-first-right-td] {
      z-index: 2 !important;
      background-color: inherit;
      // filter: drop-shadow(0 0 0.55rem lightgray)
      // -webkit-box-shadow: -8px 0px 30px 0px rgba(75,75,75,0.05); 
      // box-shadow: -8px 0px 30px 0px rgba(75, 75, 75, 0.05);
      // background-color: rgba(255, 255, 255, 0.9);
      // backdrop-filter: blur(6px);
    }
  }
}