// Typography for Sous
// An example of self-hosting instead of relying on a webfont service

@font-face {
  // Note: this is an all-caps subset of Lora
  // It will appear incorrectly with lowercase characters
  font-family: 'lorabold';
  src: url('../../fonts/lora-bold-subset.eot');
  src: url('../../fonts/lora-bold-subset.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/lora-bold-subset.woff') format('woff'),
       url('../../fonts/lora-bold-subset.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'lato';
  src: url('../../fonts/lato-regular-webfont.eot');
  src: url('../../fonts/lato-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/lato-regular-webfont.woff') format('woff'),
       url('../../fonts/lato-regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'lato';
  src: url('../../fonts/lato-italic-webfont.eot');
  src: url('../../fonts/lato-italic-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/lato-italic-webfont.woff') format('woff'),
       url('../../fonts/lato-italic-webfont.ttf') format('truetype');
  font-weight: normal;
  font-weight: 400;
  font-style: italic;

}

@font-face {
  font-family: 'lato';
  src: url('../../fonts/lato-bold-webfont.eot');
  src: url('../../fonts/lato-bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/lato-bold-webfont.woff') format('woff'),
       url('../../fonts/lato-bold-webfont.ttf') format('truetype');
  font-weight: bold;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'lato';
  src: url('../../fonts/lato-black-webfont.eot');
  src: url('../../fonts/lato-black-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/lato-black-webfont.woff') format('woff'),
       url('../../fonts/lato-black-webfont.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}


@font-face {
  font-family: 'maisonneue-bold';
  src: url('../../fonts/maisonneue-bold-webfont.eot');
  src: url('../../fonts/maisonneue-bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/maisonneue-bold-webfont.woff') format('woff'),
       url('../../fonts/maisonneue-bold-webfont.ttf') format('truetype'),
       url('../../fonts/maisonneue-bold-webfont.svg#webfont') format('svg');
  font-style: normal;
}

@font-face {
  font-family: 'maisonneue-book';
  src: url('../../fonts/maisonneue-book-webfont.eot');
  src: url('../../fonts/maisonneue-book-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/maisonneue-book-webfont.woff') format('woff'),
       url('../../fonts/maisonneue-book-webfont.ttf') format('truetype'),
       url('../../fonts/maisonneue-book-webfont.svg#webfont') format('svg');
  font-style: normal;
}

@font-face {
  font-family: 'maisonneue-demi';
  src: url('../../fonts/maisonneue-demi-webfont.eot');
  src: url('../../fonts/maisonneue-demi-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/maisonneue-demi-webfont.woff') format('woff'),
       url('../../fonts/maisonneue-demi-webfont.ttf') format('truetype'),
       url('../../fonts/maisonneue-demi-webfont.svg#webfont') format('svg');
  font-style: normal;
}

@font-face {
  font-family: 'maisonneue-light';
  src: url('../../fonts/maisonneue-light-webfont.eot');
  src: url('../../fonts/maisonneue-light-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/maisonneue-light-webfont.woff') format('woff'),
       url('../../fonts/maisonneue-light-webfont.ttf') format('truetype'),
       url('../../fonts/maisonneue-light-webfont.svg#webfont') format('svg');
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans-italic-bold';
  src: url('../../fonts/NotoSans-BoldItalic.eot');
  src: url('../../fonts/NotoSans-BoldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/NotoSans-BoldItalic.woff2') format('woff2'),
    url('../../fonts/NotoSans-BoldItalic.woff') format('woff'),
    url('../../fonts/NotoSans-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'NotoSans';
  src: url('../../fonts/NotoSans-Regular.eot');
  src: url('../../fonts/NotoSans-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/NotoSans-Regular.woff2') format('woff2'),
    url('../../fonts/NotoSans-Regular.woff') format('woff'),
    url('../../fonts/NotoSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans-italic';
  src: url('../../fonts/NotoSans-Italic.eot');
  src: url('../../fonts/NotoSans-Italic.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/NotoSans-Italic.woff2') format('woff2'),
    url('../../fonts/NotoSans-Italic.woff') format('woff'),
    url('../../fonts/NotoSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'NotoSans-demi';
  src: url('../../fonts/NotoSans-Medium.eot');
  src: url('../../fonts/NotoSans-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/NotoSans-Medium.woff2') format('woff2'),
    url('../../fonts/NotoSans-Medium.woff') format('woff'),
    url('../../fonts/NotoSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans-light';
  src: url('../../fonts/NotoSans-Light.eot');
  src: url('../../fonts/NotoSans-Light.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/NotoSans-Light.woff2') format('woff2'),
    url('../../fonts/NotoSans-Light.woff') format('woff'),
    url('../../fonts/NotoSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans-bold';
  src: url('../../fonts/NotoSans-Bold.eot');
  src: url('../../fonts/NotoSans-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/NotoSans-Bold.woff2') format('woff2'),
    url('../../fonts/NotoSans-Bold.woff') format('woff'),
    url('../../fonts/NotoSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

// if we decide to use noto serif we need to add the files in the font folder and use this code
// @font-face {
//   font-family: 'NotoSerif-light';
//   src: url('../../fonts/NotoSerif-Light.eot');
//   src: url('../../fonts/NotoSerif-Light.eot?#iefix') format('embedded-opentype'),
//       url('../../fonts/NotoSerif-Light.woff2') format('woff2'),
//       url('../../fonts/NotoSerif-Light.woff') format('woff'),
//       url('../../fonts/NotoSerif-Light.ttf') format('truetype');
//   font-weight: 300;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'NotoSerif-bold';
//   src: url('../../fonts/NotoSerif-Bold.eot');
//   src: url('../../fonts/NotoSerif-Bold.eot?#iefix') format('embedded-opentype'),
//       url('../../fonts/NotoSerif-Bold.woff2') format('woff2'),
//       url('../../fonts/NotoSerif-Bold.woff') format('woff'),
//       url('../../fonts/NotoSerif-Bold.ttf') format('truetype');
//   font-weight: bold;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'NotoSerif-demi';
//   src: url('../../fonts/NotoSerif-Medium.eot');
//   src: url('../../fonts/NotoSerif-Medium.eot?#iefix') format('embedded-opentype'),
//       url('../../fonts/NotoSerif-Medium.woff2') format('woff2'),
//       url('../../fonts/NotoSerif-Medium.woff') format('woff'),
//       url('../../fonts/NotoSerif-Medium.ttf') format('truetype');
//   font-weight: 500;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'NotoSerif-italic-bold';
//   src: url('../../fonts/NotoSerif-BoldItalic.eot');
//   src: url('../../fonts/NotoSerif-BoldItalic.eot?#iefix') format('embedded-opentype'),
//       url('../../fonts/NotoSerif-BoldItalic.woff2') format('woff2'),
//       url('../../fonts/NotoSerif-BoldItalic.woff') format('woff'),
//       url('../../fonts/NotoSerif-BoldItalic.ttf') format('truetype');
//   font-weight: bold;
//   font-style: italic;
// }

// @font-face {
//   font-family: 'NotoSerif';
//   src: url('../../fonts/NotoSerif-Regular.eot');
//   src: url('../../fonts/NotoSerif-Regular.eot?#iefix') format('embedded-opentype'),
//       url('../../fonts/NotoSerif-Regular.woff2') format('woff2'),
//       url('../../fonts/NotoSerif-Regular.woff') format('woff'),
//       url('../../fonts/NotoSerif-Regular.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'NotoSerif-italic';
//   src: url('../../fonts/NotoSerif-Italic.eot');
//   src: url('../../fonts/NotoSerif-Italic.eot?#iefix') format('embedded-opentype'),
//       url('../../fonts/NotoSerif-Italic.woff2') format('woff2'),
//       url('../../fonts/NotoSerif-Italic.woff') format('woff'),
//       url('../../fonts/NotoSerif-Italic.ttf') format('truetype');
//   font-weight: normal;
//   font-style: italic;
// }
