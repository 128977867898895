@import "tailwindcss/base.css";
@import "tailwindcss/components.css";
@import "tailwindcss/utilities.css";

// Mixins & Utilities
@import './utils/reset.import.less';
@import './utils/helpers.import.less';
@import './utils/lesshat.import.less';
@import './utils/fontface.import.less';
@import './utils/text.import.less';
@import './utils/typography.import.less';
@import './utils/variables.import.less';

// Global namespace
@import './globals/base.import.less';
@import './globals/button.import.less';
@import './globals/common.import.less';
@import './globals/form.import.less';
@import './globals/icomoon.import.less';
@import './globals/icons.import.less';
@import './globals/layout.import.less';
@import './globals/link.import.less';
@import './globals/table.import.less';
@import './globals/user.import.less';
@import './globals/tours.import.less';
@import './globals/loading.import.less';

// Components
@import './accounts.import.less';
@import './components/notifications.import.less';
@import './components/companies_list.import.less';
@import './components/editor_courses.import.less';
@import './components/user_study_overview.import.less';
@import './components/user_study_content.import.less';
@import './components/heartbeat.import.less';
@import './components/notifications.import.less';
@import './components/engagement_view.import.less';
@import './components/quill_editor_import.less';
@import './components/ratings.import.less';

@import "react-quill/dist/quill.snow.css";
@import "react-datepicker/dist/react-datepicker.css";

// Tippy
@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/animations/scale-subtle.css';