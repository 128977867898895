.error-message {
  color: #B50700;
}
.warn-message {
  color: #9F6000;;
}
.info-message {
  color: #00529B;
}
.success-message {
  color: #4F8A10;
}

form {
  label.mui {
    display: inline-block;
    color: rgba(0,0,0,0.5);
    .rem-px(font-size, 12);
    font-weight: 600;
    .rem-px(padding-bottom, 10);
    .rem-px(margin-top, 14);
  }
  .error-message, .warn-message, .info-message, .success-message {
    font-size: 12px;
    line-height: 12px;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  }
  .help {
    position: relative;
    height: 0;
    width: 98%;
    &:before {
      position: absolute;
      top: 8px;
      right: 8px;
      margin-top: -8px;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid #bbb;
      content: "";
    }
    .fire-help {
      position: absolute;
      z-index: 1;
      background-color: rgba(255, 255, 240, 70);
      top: 8px;
      right: 0;
      width: 400px;
    }
  }
}

.filter {
  .filter-group {
    label {
      color: rgba(0, 0, 0, 0.4);
    }
    .rem-px(padding-top, 10);
    .rem-px(padding-bottom, 10);
  }
}